/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaCircle, FaRegCircle } from 'react-icons/fa';
import Masthead from '@/components/layout/masthead';
import SEO from '@/components/layout/seo';
import Initials from '@/components/initials';
import { getMyAssessments, createNewAssessment } from '@/services/api';
import { formatRelative } from 'date-fns';
import { calculateReportData } from '@/services/idm';
import IdmMastheadContent from '@/components/IdmMastheadContent';
import LoginContent from '../../LoginContent';

const Page = () => {
  const data = useStaticQuery(graphql`
    {
      allIndicatorsXlsxIndicators {
        nodes {
          identifier
          group
          type
        }
      }
    }
  `);
  const allIndicators = data.allIndicatorsXlsxIndicators.nodes;

  // EDMUND - THis is a react hook, tries to get assessments to display to index page
  const [myAssessments, setMyAssessments] = useState([]);
  const [error, setError] = useState(false);
  useEffect(() => {
    async function fetchData() {
      let sa = await getMyAssessments();
      if (!sa.error) {
        setMyAssessments(sa.data);
      } else {
        setError(sa.message);
      }
    }
    fetchData();

    // Logs user out due to new IDM site we are using now
    window.localStorage.removeItem('idm_user');
    window.localStorage.removeItem('idm_token');
  }, []);

  // create new assessment
  const newAssessment = async () => {
    let assessment = await createNewAssessment();
    navigate(`/app/idm/${assessment.data.id}`);
  };

  return (
    <React.Fragment>
      <SEO title="Implementation Development Map" keywords={[]} />
      {/* fixed jsx content from src/components folder */}
      <Masthead>
        <IdmMastheadContent />
        <Container className="my-4">
          <Row className="shadow p-3 mb-5 bg-white rounded">
            <Col lg={7}>
            <h3>Self-Assessments</h3>
            <p>
              Use the IDM to describe, assess, and identify priority areas for
              improvement of your state pre-K infrastructure and program/policy
              implementation.
            </p>
            {error && (
              <div
                className="alert alert-warning"
                dangerouslySetInnerHTML={{ __html: error }}
              ></div>
            )}
            </Col>
            <Col lg={{ span: 3, offset: 1 }}>
            <p>
              <a
                onClick={(e) => newAssessment(e)}
                className="btn btn-primary cute-button mt-3"
              >
                Start a New Self-Assessment
              </a>
            </p>
            </Col>
          </Row>
        </Container>
      </Masthead>
      <Container>
        <Row>
        <Col className="py-5" lg={12}>
            <LoginContent />
        </Col>
        </Row>
        <Row className="mb-5">
          {myAssessments.map((itm) => {
            let reportData = calculateReportData(itm.response, allIndicators);
            let total = 0;
            const dots = Object.entries(reportData).map((section) => {
              if (section[1].complete) {
                total++;
                return <FaCircle key={section[0]} className="mr-1" />;
              } else {
                return <FaRegCircle key={section[0]} className="mr-1" />;
              }
            });
            let complete = total == Object.keys(reportData).length;
            return (
              <Col key={itm.id} lg={4}>
                <Card
                  className="p-4 flex-column text-idm-black my-2"
                  style={{ height: '330px', cursor: 'pointer' }}
                  onClick={(e) => {
                    navigate(`/app/idm/${itm.id}`);
                  }}
                >
                  <h4 style={{ minHeight: '60px' }}>{itm.name}</h4>
                  <div className="d-flex align-items-center">
                    {dots}
                    {total}/7 elements
                  </div>
                  <div class="my-2" style={{ minHeight: '40px' }}>
                    {complete && (
                      <Link
                        to={`/app/idm/report/${itm.id}`}
                        className="standalone mt-2 mb-2"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        View Score Report
                      </Link>
                    )}{' '}
                  </div>
                  <div className="d-flex my-2 ml-n1">
                    {itm.users.map((user) => (
                      <Initials key={user.id} user={user} />
                    ))}
                    <Initials user={itm.owner} />
                  </div>
                  <div className=" d-flex mt-2">
                    {itm.updated_at && (
                      <div>
                        Updated:{' '}
                        {formatRelative(new Date(itm.updated_at), new Date())}
                      </div>
                    )}
                  </div>
                  <div className=" d-flex my-2">
                    {itm.created_at && (
                      <div>
                        Created:{' '}
                        {formatRelative(new Date(itm.created_at), new Date())}
                      </div>
                    )}
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
      <div className="background-idm-light-gray mt-5">
        <Container className="py-4 py-lg-5">
          <Row className="py-3">
            <Col lg={5}>
              <h2 className="mt-0 mb-3 mb-lg-0">Contact Us</h2>
            </Col>
            <Col lg={5}>
              <p className="my-0">
                For questions about the Partnership for Pre-K Improvement
                Toolkit, please contact{' '}
                <a href="mailto:ppitoolkitinfo@uw.edu">ppitoolkitinfo@uw.edu</a>
                .
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Page;
